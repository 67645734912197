@font-face {
  font-family: matter;
  src: url(./fonts/MatterTRIAL-Light.otf) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: matter;
  src: url(./fonts/MatterSQTRIAL-LightItalic.otf) format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: matter;
  src: url(./fonts/MatterTRIAL-Regular.otf) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: matter;
  src: url(./fonts/MatterTRIAL-Medium.otf) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: matter;
  src: url(./fonts/MatterTRIAL-MediumItalic.otf) format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: matter;
  src: url(./fonts/MatterTRIAL-SemiBold.otf) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  min-height: 100%;
}
body {
  font-family: matter, helvetica, arial, sans-serif;
  color: #292a2d;
  background-color: #fbfbf6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 100%;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::selection {
  background-color: #47a3f3;
  color: #fefefe;
}

p {
  white-space: pre-wrap;
}
.dsc {
  min-height: 80px;
}
.dscvr a {
  color: #573033;
}
#features .mbpadding {
  padding-left: 0;
  padding-right: 0;
}
.mbpadding {
  padding-left: 0;
  padding-right: 0;
}
.btnn {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
a.btn.is-white.is-large.is-block {
  margin-bottom: 30px;
  margin-top: 40px;
}
.sq p {
  font-size: 14px !important;
}
.animate-bouncing-arrow {
  -webkit-animation: bouncing-arrow 2s infinite;
  animation: bouncing-arrow 2s infinite;
  transform-origin: center bottom;
}

.arrow {
  appearance: none !important;
  display: inline-block !important;
  border-radius: 90px !important;
  border: none !important;
  outline: none !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 48px;
  height: 48px;
  color: rgb(255, 255, 255);
  cursor: pointer !important;
  touch-action: manipulation !important;
  position: relative !important;
  background: transparent;
  transition: -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important;
}
.sqblock:hover {
  box-shadow: rgb(0 0 0 / 12%) 0px 1.25rem 2.5rem 0px;
}
.sqblock:hover .arrow:hover {
  background: rgb(74, 74, 74) !important;
  color: rgb(255, 255, 255) !important;
}

.theme--softgradient,
.foot,
.softgradient {
  background: linear-gradient(108.89deg, rgba(228, 221, 211, 0.6) 1.55%, rgba(246, 234, 216, 0) 91.67%);
}
.theme.theme--new {
  background: linear-gradient(115.27deg, #eaece3 0.42%, rgba(234, 236, 227, 0) 100.92%);
}
.arrow::before {
  content: "" !important;
  display: block !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 48px !important;
  height: 48px !important;
  border-radius: 90px !important;
  background: transparent !important;
  border: 2px solid rgb(176, 176, 176) !important;
}

.arrow:active {
  color: rgb(255, 255, 255) !important;
  transform: scale(0.92) !important;
}

.btnn:active {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.ft {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(242, 237, 223, 0.5)),
    color-stop(35%, #fff)
  );
  background-image: linear-gradient(180deg, rgba(242, 237, 223, 0.5), #efefef 35%);
}

a {
  color: inherit;
  text-decoration: none;
}

.block-content a {
  margin-top: 20px;
}
.cole {
  background: linear-gradient(89.59deg, #eff2ee 1.68%, rgba(240, 242, 238, 0) 99.69%);
}

* {
  box-sizing: border-box;
}
.mble a {
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 24px;
  letter-spacing: -0.24px;
  font-weight: 400;
}
.mble .btn {
  font-size: 16px;
  font-weight: 500;
}
.btn {
  padding: 9px 23px;
  border-radius: 48px;
  color: #fff;
  background-color: #183837;
  border: 1px solid transparent;
  transition: opacity, color, background-color, border;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.16, 1.08, 0.38, 0.98);
  display: inline-flex;
  position: relative;
  z-index: 0;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;
  font-weight: 700;
  -webkit-transition: box-shadow 0.2s ease, -webkit-transform 0.1s ease, transform 0.1s ease !important;
  -moz-transition: box-shadow 0.2s ease, transform 0.1s ease !important;
  transition: box-shadow 0.2s ease, -ms-transform 0.1s ease, -webkit-transform 0.1s ease, transform 0.1s ease !important;
  -webkit-tap-highlight-color: transparent !important;
  letter-spacing: 0.025em;

  &:hover {
    background-color: #0e272f;
    color: #fff;
    fill: #463f3c;
    border: 1px solid rgba(70, 63, 60, 0);
  }

  &.bg-white {
    background-color: #fbfbf6;
    color: #463f3c;

    &:hover {
      border-color: #463f3c;
      background-color: #fff;
      color: #463f3c;
      opacity: 1;
    }
  }
}

.secondary {
  @apply border border-neutral-400 bg-neutral-100 fill-neutral-700 text-neutral-700;

  &:hover {
    @apply bg-white text-neutral-700;
  }
}

.theme {
  &.theme--light {
    @apply bg-neutral-100;
    p,
    h1,
    h2,
    h3,
    h4 {
      @apply text-accent-800;
    }
    .typed-text {
      @apply text-accent-900;
    }
    ul > li {
      @apply text-accent-900;
      svg {
        @apply bg-accent-700;
        path {
          @apply fill-white;
        }
      }
    }
    .btn {
      @apply bg-accent-700 text-white hover:border-neutral-700 hover:bg-accent-800;
    }
    .list {
      text-align: left;
      @apply text-neutral-100;
      svg.bg-white {
        @apply bg-accent-400;
        path {
          @apply fill-white;
        }
      }
    }
  }
  &.theme--new {
    @apply bg-[#F0F2EE];
    p,
    h1,
    h2,
    h3,
    h4 {
      @apply text-accent-700;
    }
    .typed-text {
      @apply text-accent-900;
    }
    .theme--icon {
      svg {
        path {
          @apply fill-accent-500;
        }
      }
    }
    .btn {
      @apply bg-accent-700 text-white hover:border-neutral-700 hover:bg-accent-800;
    }
    .list {
      @apply text-neutral-100;
      svg.bg-white {
        @apply bg-accent-900;
        path {
          @apply fill-neutral-100;
        }
      }
    }
  }

  &.theme--softgradient {
    @apply bg-[#FEFBF3];
    p,
    h1,
    h2,
    h3,
    h4 {
      @apply text-accent-700;
    }
    .typed-text {
      @apply text-accent-900;
    }
    .theme--icon {
      svg {
        path {
          @apply fill-accent-500;
        }
      }
    }
    .btn {
      @apply bg-accent-700 text-white hover:border-neutral-700 hover:bg-accent-800;
    }
    .list {
      @apply text-neutral-100;
      svg.bg-white {
        @apply bg-accent-900;
        path {
          @apply fill-neutral-100;
        }
      }
    }
  }

  &.theme--soft {
    @apply bg-[#EEEEE2];
    p,
    h1,
    h2,
    h3,
    h4 {
      @apply text-accent-900;
    }
    .typed-text {
      @apply text-accent-700;
    }
    ul > li {
      @apply text-accent-900;
      svg {
        @apply bg-accent-700;
        path {
          @apply fill-white;
        }
      }
    }
    .btn {
      @apply bg-accent-700 text-white hover:border-neutral-700 hover:bg-accent-800;
    }
    .list {
      @apply text-neutral-100;
      svg.bg-white {
        @apply bg-accent-900;
        path {
          @apply fill-neutral-100;
        }
      }
    }
  }

  &.theme--dark {
    @apply bg-accent-700;
    p,
    h1,
    h2,
    h3,
    h4,
    a.underline {
      @apply text-[#FEFBF3];
    }
    .typed-text {
      @apply text-neutral-300;
    }
    &.theme--icon svg {
      path {
        @apply fill-neutral-100;
      }
    }
    ul > li {
      @apply text-neutral-100;
      svg {
        @apply bg-neutral-100;
        path {
          @apply fill-neutral-700;
        }
      }
    }
    .btn {
      @apply border bg-neutral-100 text-neutral-700 hover:border-transparent hover:bg-white;
    }
    .list {
      @apply text-neutral-100;
      svg.bg-transparent {
        path {
          @apply fill-neutral-100;
        }
      }
    }
  }
}
